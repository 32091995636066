<template>
	<div class="p-grid panel-demo">
		<div class="p-col-12 p-lg-12 ">
			<i class="bi bi-credit-card" style="color: #d46900;fontSize: 1.5rem"> Formas de Pago</i>
		</div>	
		<div class="p-col-12 p-md-3">
			<div class="card">
				<Card>
					<template #header>
                        <img alt="logo" src="images/zelle.jpg">
					</template>
					<template #title>
						<h5>Pago por Zelle</h5>
					</template>

					<template #content>
						<p>Es una manera rápida, segura y fácil de pagar a una cuenta bancaria en EE. UU.</p>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-3">
			<div class="card">
				<Card>
					<template #header>
                        <img alt="logo" src="images/paypal.jpg">
					</template>
					<template #title>
						<h5>Aceptamos PayPal</h5>
					</template>

					<template #content>
						<p>es una billetera virtual que podrás utilizar para realizar tus pagos a través de 
							Internet.</p>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-3">
			<div class="card">
				<Card>
					<template #header>
                        <img alt="logo" src="images/banesco.jpg">
					</template>
					<template #title>
						<h5>Transferencia</h5>
					</template>

					<template #content>
						<p>Aceptamos transfencia a nuestras cuenta internacional en Banesco</p>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-3">
			<div class="card">
				<Card>
					<template #header>
                        <img alt="logo" src="images/btc.jpg">
					</template>
					<template #title>
						<h5>BTC</h5>
					</template>

					<template #content>
						<p>Puede pagar desde su dispositivo móvil, con nuestro código QR.</p>
					</template>
				</Card>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				toolbarItems: [
					{
						label: 'Save',
						icon: 'pi pi-check'
					},
					{
						label: 'Update',
						icon: 'pi pi-upload'
					},
					{
						label: 'Delete',
						icon: 'pi pi-trash'
					},
					{
						label: 'Home Page',
						icon: 'pi pi-home'
					},
				]
			}
		}
	}
</script>

<style scoped lang="scss">
	p {
		line-height: 1.5;
		margin: 0;
	}

	::v-deep(.p-card .p-card-title) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
	}
</style>
